import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NavigationBar from './components/Navbar'; // Actualizamos la importación
import Home from './components/Home';
import Services from './components/Services';
import Contact from './components/Contact';
import ImageSlider from './components/ImageSlider'; // Puedes usar este componente como 'Objetivos'
import Footer from './components/Footer';
import './FolderViewer.css';
import EquipmentPage from './components/EquipmentPage';

function App() {
  return (
    <Router>
      <NavigationBar /> {/* Usamos el componente NavigationBar aquí */}
      <div className="container mt-4">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<ImageSlider />} />
          <Route path="/EquipmentPage" element={<EquipmentPage />} />
          
        </Routes>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
